import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchStudentReportCard(calendar) {
  const { studentId } = useParams();

  const [loading, setLoading] = useState(false);
  const [reportCard, setReportCard] = useState(null);
  const [scorecards, setScorecards] = useState(null);

  const request = useCallback(
    async (_calendar = calendar) => {
      setReportCard(null);
      setScorecards([]);
      setLoading(true);

      try {
        const res = await client.get(
          `/student/${studentId}/reportcard?session=${_calendar?.session}&term=${_calendar?.term}`
        );

        if (res.data?.success) {
          setReportCard(res.data?.data);

          setScorecards(res.data?.data?.scorecards);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error);
      }
    },
    [studentId, calendar]
  );

  useEffect(() => {
    if (calendar) request(calendar);
  }, [request, calendar]);

  return { reportCard, scorecards, loading, request };
}

import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { BiArrowBack } from "react-icons/bi";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { IoCloudDownloadOutline } from "react-icons/io5";

import {
  selectCalendar,
  selectSchoolDetails,
} from "../../../redux/slices/school/selectors";
import {
  getAverage,
  getGrade,
  getRemark,
  getStudentClassStatus,
  handleToast,
} from "../../../shared/utils/functions";

import PageLoader from "../../../shared/components/PageLoader";
import useFetchStudentBasicInfo from "../../../shared/api/hooks/useFetchStudentBasicInfo";
import useFetchStudentReportCard from "../../../shared/api/cards/useFetchStudentReportCard";
import ReportCard from "../../../shared/components/classes/studentPreviewReportCard/ReportCard";
import useFetchStudentClassHistory from "../../../shared/api/archives/students/useFetchStudentClassHistory";
import useFetchCalendars from "../../../shared/api/calendar/useFetchCalendars";

const StudentPreviewReportCard = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const session = query.get("session");
  const term = query.get("term");

  const reportCardRef = useRef();
  const { classId, schoolId, studentId } = useParams();

  const calendar = useSelector(selectCalendar);
  const school = useSelector(selectSchoolDetails);

  const { data: calendars } = useFetchCalendars(school?.state);
  const [selectedCalendar, setSelectedCalendar] = useState(null);

  const { loading: studentDetailsLoading, data: studentDetails } =
    useFetchStudentBasicInfo(studentId);

  const {
    reportCard,
    scorecards,
    loading: reportCardLoading,
  } = useFetchStudentReportCard(selectedCalendar);

  const {
    data: classHistories,
    loading: loadingClassHistories,
    request: fetchClassHistory,
  } = useFetchStudentClassHistory();

  useEffect(() => {
    if (reportCard && reportCard?.term === "third") {
      fetchClassHistory({
        studentId: reportCard?.studentId,
        classId: reportCard?.classId,
        session: reportCard?.session,
      });
    }
  }, [reportCard, fetchClassHistory]);

  useEffect(() => {
    if (term && session && calendars?.length) {
      const found = calendars?.find(
        (el) => (el?.session === session) & (el?.term === term)
      );

      if (!found)
        return handleToast("No calendar for selected term", "warning");

      setSelectedCalendar(found);
    }
  }, [term, session, calendars]);

  if (reportCardLoading || studentDetailsLoading || loadingClassHistories) {
    return <PageLoader loading={reportCardLoading || studentDetailsLoading} />;
  }

  return (
    <div>
      <div className="page">
        <div className="mb-5 flex items-center justify-between">
          <div className="page_title_container">
            <h1 className="page_title">
              {`${studentDetails?.lastName || ""} ${
                studentDetails?.firstName || ""
              }'s`}{" "}
              Report Card
            </h1>
          </div>

          <ReactToPrint
            trigger={() => (
              <button
                className="flex items-center justify-center gap-2 bg-primary h-8 xxs:h-8 ssm:h-9 rounded-sm text-white px-3 min-w-[80px]"
                onClick={() => {}}
              >
                <IoCloudDownloadOutline className="text-sm" />
                <small>Download</small>
              </button>
            )}
            content={() => reportCardRef.current}
          />
        </div>

        <div className="bg-white p-3">
          <NavLink
            to={`/${schoolId}/classes/${classId}/students/${studentId}/reportcard?session=${session}&term=${term}`}
          >
            <button className="px-3 py-2 flex items-center gap-2 text-yankee_blue hover:bg-primary_light_30 hover:text-primary">
              <BiArrowBack className="text-sm" />
              <small>Back to cards</small>
            </button>
          </NavLink>
        </div>

        <div className="main_content">
          <ReportCard
            ref={reportCardRef}
            school={school}
            subjects={scorecards}
            reportCard={reportCard}
            getGrade={getGrade}
            getRemark={getRemark}
            getAverage={getAverage}
            vacationDate={new Date(calendar?.vacationStartDate)?.toDateString()}
            closingDate={new Date(calendar?.closingDate)?.toDateString()}
            nextResumptionDate={new Date(
              calendar?.nextResumptionDate
            )?.toDateString()}
            endSessionRemark={getStudentClassStatus(classHistories?.[0], "end")}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentPreviewReportCard;

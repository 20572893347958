import { useParams } from "react-router-dom";
import { Country, State } from "country-state-city";
import { useEffect, useRef, useState } from "react";

import Avatar from "../../Avatar";
import FormInput from "../../form/FormInput";
import FormButton from "../../form/FormButton";
import SelectInput from "../../form/SelectInput";
import useUpdateStudentBasicInfo from "../../../api/classes/students/useUpdateStudentBasicInfo";

const BasicDetailsForm = ({ category, details, closeSideModal }) => {
  const { studentId } = useParams();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  const { request: updateBasicInfoRequest, loading: updating } =
    useUpdateStudentBasicInfo();
  const [student, setStudent] = useState({
    firstName: details?.firstName || "",
    lastName: details?.lastName || "",
    email: details?.email || "",
    address: details?.address || "",
    phone: details?.phone || "",
    dob: details?.dob || "",
    gender: details?.gender || "",
    studentImage: details?.studentImage || "",
    religion: details?.religion || "",
    ethnicity: details?.ethnicity || "",
    lga: details?.lga || "",
    nationality: details?.nationality || "",
    stateOfOrigin: details?.stateOfOrigin || "",
  });

  useEffect(() => {
    setStudent({
      firstName: details?.firstName || "",
      lastName: details?.lastName || "",
      email: details?.email || "",
      address: details?.address || "",
      phone: details?.phone || "",
      dob: details?.dob || "",
      gender: details?.gender || "",
      studentImage: details?.studentImage || "",
      religion: details?.religion || "",
      ethnicity: details?.ethnicity || "",
      lga: details?.lga || "",
      nationality: details?.nationality || "",
      stateOfOrigin: details?.stateOfOrigin || "",
    });
  }, [details]);

  const countries = Country.getAllCountries()?.map((country) => ({
    value: country.isoCode,
    ...country,
  }));

  const states = (countryCode) =>
    State.getStatesOfCountry(countryCode).map((state) => ({
      value: state.isoCode,
      ...state,
    }));

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const filePickerRef = useRef(null);

  function resetFilePickerRef() {
    filePickerRef.current.value = "";
    setSelectedFile(null);
    setSelectedImage(null);
  }

  function selectImage(e) {
    const reader = new FileReader();
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }

    reader.onload = (readerEvent) => {
      setSelectedImage(readerEvent.target.result);
    };

    setSelectedFile(e.target.files[0]);
  }

  useEffect(() => {
    if (details?.nationality) {
      const [nationality] = Country.getAllCountries()?.filter(
        (country) => country?.name?.toLocaleLowerCase() === details?.nationality
      );

      setSelectedCountry(nationality?.isoCode);
    }
  }, [details?.nationality]);

  async function updateBasicInfo() {
    const formData = new FormData();

    formData.append("image", selectedFile);
    formData.append("firstName", student?.firstName);
    formData.append("lastName", student?.lastName);
    formData.append("email", student?.email);
    formData.append("address", student?.address);
    formData.append("phone", student?.phone);
    formData.append("dob", student?.dob);
    formData.append("gender", student?.gender);
    formData.append("religion", student?.religion);
    formData.append("lga", student?.lga);
    formData.append("ethnicity", student?.ethnicity);

    if (selectedCountry && !selectedState) {
      const country = Country.getCountryByCode(selectedCountry);
      formData.append("nationality", country.name);
    }

    if (selectedCountry && selectedState) {
      const country = Country.getCountryByCode(selectedCountry);
      const state = State.getStateByCodeAndCountry(
        selectedState,
        selectedCountry
      );

      formData.append("nationality", country.name);
      formData.append("stateOfOrigin", state.name);
    }

    await updateBasicInfoRequest(studentId, formData);
    closeSideModal({ refresh: true, type: "basic" });
  }

  return (
    <div className="">
      <h3 className="text-black-text font-semibold mb-5">Basic Information</h3>

      <div className="flex items-center justify-center flex-col gap-2 mb-4">
        <Avatar imageUrl={selectedImage || student?.studentImage} size={80} />

        <input
          type={"file"}
          hidden
          name="image"
          ref={filePickerRef}
          onChange={selectImage}
          accept=".jpg, .jpeg, .png"
        />

        <div className="flex gap-2">
          <button
            className="flex items-center text-xs md:text-sm gap-1 border rounded-md px-2 py-1 cursor-pointer bg-primary text-white font-semibold"
            onClick={() => filePickerRef.current.click()}
          >
            <small>Change image</small>
          </button>

          {selectedFile ? (
            <button
              className="flex items-center text-xs md:text-sm gap-1 rounded-md px-2 py-1 cursor-pointer bg-white text-red-light border border-red-light font-semibold"
              onClick={() => resetFilePickerRef()}
            >
              <small>Cancel image</small>
            </button>
          ) : null}
        </div>
      </div>

      <div className="">
        <FormInput
          label={"First name"}
          value={student?.firstName || ""}
          onChange={(e) =>
            setStudent({
              ...student,
              firstName: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Last name"}
          value={student?.lastName || ""}
          onChange={(e) =>
            setStudent({
              ...student,
              lastName: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Email address"}
          value={student?.email || ""}
          onChange={(e) =>
            setStudent({
              ...student,
              email: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Home address"}
          value={student?.address || ""}
          onChange={(e) =>
            setStudent({
              ...student,
              address: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Contact"}
          value={student?.phone || ""}
          maxLength={11}
          onChange={(e) =>
            setStudent({
              ...student,
              phone: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <FormInput
          label={"Date of birth"}
          value={student?.dob || ""}
          type="date"
          onChange={(e) =>
            setStudent({
              ...student,
              dob: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <SelectInput
          label={"Gender"}
          placeholder="Gender"
          value={student?.gender || ""}
          handleSelect={(e) =>
            setStudent({ ...student, gender: e.currentTarget.value })
          }
          options={[
            { name: "male", value: "male" },
            { name: "female", value: "female" },
          ]}
          className="mb-2"
        />

        <SelectInput
          label={"Religion"}
          placeholder="Religion"
          value={student?.religion || ""}
          handleSelect={(e) =>
            setStudent({ ...student, religion: e.currentTarget.value })
          }
          options={[
            { name: "Islam", value: "islam" },
            { name: "Christianity", value: "christianity" },
            { name: "Other", value: "Other" },
          ]}
          className="mb-2"
        />

        <FormInput
          label={"Ethnicity"}
          value={student?.ethnicity || ""}
          onChange={(e) =>
            setStudent({
              ...student,
              ethnicity: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        <SelectInput
          label={`Nationality ${
            student?.nationality ? "(" + student?.nationality + ")" : ""
          }`}
          placeholder="Country"
          handleSelect={(e) => setSelectedCountry(e.currentTarget.value)}
          options={countries}
          value={selectedCountry || ""}
          className="mb-2"
        />

        <SelectInput
          label={`State of origin ${
            student?.stateOfOrigin ? "(" + student?.stateOfOrigin + ")" : ""
          }`}
          placeholder="State"
          handleSelect={(e) => setSelectedState(e.currentTarget.value)}
          options={states(selectedCountry)}
          className="mb-2"
        />

        <FormInput
          label={"L.G.A"}
          value={student?.lga || ""}
          onChange={(e) =>
            setStudent({
              ...student,
              lga: e.currentTarget.value,
            })
          }
          className="mb-2"
        />

        {category?.startsWith("S") ? (
          <SelectInput
            label={"Department"}
            placeholder="Department"
            required
            handleSelect={(e) =>
              setStudent({ ...student, department: e.currentTarget.value })
            }
            options={[
              { name: "Science Department", value: "science" },
              { name: "Art Department", value: "art" },
              { name: "Commercial Department", value: "commercial" },
            ]}
            className="mb-2"
          />
        ) : null}
      </div>

      <div className="max-w-xs mx-auto my-10">
        <FormButton
          title={"Update"}
          onClick={updateBasicInfo}
          loading={updating}
        />

        {/* <div style={{ minWidth: "100px" }} /> */}
      </div>
    </div>
  );
};
export default BasicDetailsForm;
